import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare } from '@fortawesome/free-solid-svg-icons';
import Image from '../../images/blue.jpg'

const Button = styled.button`
  background-image: url(${Image});
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  transition: 0.2s ease-in-out;
  margin: 1rem 2rem 2rem 0rem;
  
  &:hover {
    transform: scale(1.04);
    background-color: #B80000;
    color: #fff;
  }

  &:focus {
    outline: none;
  }
`;

const ShareButton = ({ url }) => {
  const handleClick = () => {
    if (navigator.share) {
      navigator.share({
        title: document.title,
        url: window.location.href,
      });
    } else {
      window.prompt('Copy to clipboard: Ctrl+C, Enter', window.location.href);
    }
  };

  return (
    <Button onClick={handleClick}>
      <FontAwesomeIcon icon={faShare} />
      Compartir
    </Button>
  );
};

export default ShareButton;


