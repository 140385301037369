import React from 'react';
import { NewsWrapper, LargeNews, SmallNews} from './Elements'
import useFetchData from '../Hooks/userFetchData';
import TextTruncate from 'react-text-truncate';
import { Link } from 'react-router-dom';

const Newsmore = () => {
  const { loading, result, error } = useFetchData(
    'https://strapi-133505-0.cloudclusters.net/api/destacadoss?populate=*',
    { cache: 'no-cache' }
  );

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error...</p>;

  const sortedResult = result.data.sort(
    (a, b) => new Date(b.attributes.Data) - new Date(a.attributes.Data)
  );
  const firstPost = sortedResult[0];
  const baseUrl = 'https://strapi-133505-0.cloudclusters.net';
  const firstImage = firstPost.attributes.Image.data.map(
    (item) => baseUrl + item.attributes.formats.small.url
  );
  const firstImageUrl = firstImage[0];
  const firstTitle = firstPost.attributes.TitleForPostNews;
  const dataPost = firstPost.attributes.Data;
  const firstDescription = firstPost.attributes.Description;
  const content = firstPost.attributes.Content;
  const localitation = firstPost.attributes.Localitation;

  return (
      
    <NewsWrapper id='destacadas'>
      <LargeNews>
      <Link to={`/destacados/news-more/${firstPost.id}`} style={{ textDecoration: 'none', color: '#000' }}>

        <img src={firstImageUrl} alt={firstTitle} style={{ filter: 'saturate(140%)' }}/>
        <h2>{firstTitle}</h2>
        <p>{firstDescription}</p>
        <TextTruncate
          style={{ color:'#5A5A5A',fontSize:'1rem' }}
          key='item-first'
          line={5}
          element="p"
          truncateText='...'
          text={content}
                        />
        <p style={{ fontSize:'1.1rem', marginTop:'1rem', color:'#B80000', fontStyle: 'oblique' }} >{localitation}</p>
        <p style={{ fontSize:'0.9rem', marginTop:'-1rem', color:'#B80000', fontStyle: 'oblique' }} >{dataPost}</p>
        </Link>
      </LargeNews>
      <SmallNews>
        {sortedResult.slice(1, 3).map((post) => {
          const image = post.attributes.Image.data.map(
            (item) => baseUrl + item.attributes.formats.small.url
          );
          const imageUrl = image[0];
          const title = post.attributes.TitleForPostNews;
          const postDate = post.attributes.Data;
          return (
            <Link to={`/destacados/news-more/${post.id}`} style={{ textDecoration: 'none', color: '#000' }}>
            <div key={post.id}>
              <img src={imageUrl} alt={title} style={{ filter: 'saturate(140%)' }} />
              <h3>{title}</h3>
              <TextTruncate
                style={{ color:'#5A5A5A',fontSize:'0.9rem' }}
                key='item-first'
                line={2}
                element="p"
                truncateText='...'
                text={content}
                        />
              <p style={{ fontSize:'1.1rem', marginTop:'0.2rem',color:'#B80000', fontStyle: 'oblique'  }} >{localitation}</p>
              <p style={{ fontSize:'0.9rem',color:'#B80000', fontStyle: 'oblique'  }} >{postDate}</p>
            </div>
            </Link>
          );
        })}
      </SmallNews>
    </NewsWrapper>
      
  );
};

export default Newsmore;
