import styled from "styled-components";
import { Link } from 'react-router-dom';

export const InfoContainer = styled.div`
    color: #fff;
    background-image: linear-gradient(to right, #1d915f,#c6f7e8); 

    @media screen and (max-width: 768px) {
        max-width: 110%;
        background: #1d915f;
        padding-bottom: 350px;
        padding-top: 50px;
    }
`;

export const InfoWrapper = styled.div`
    display: grid;
    z-index: 1;
    height: 650px;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;
    
    @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const InfoRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: ${({ imgStart }) => 
    imgStart ? `'col2 col1'` : `'col1 col2'`};
    
    @media screen and (max-width: 768px) {
        width:100%;
        grid-template-areas: ${({imgStart}) => 
        imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'` };
    }
`;

export const Column1 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col1;   
`;

export const Column2 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area:col2;   
`;

export const TexWrapper = styled.div`
    max-width: 540px;
    /* padding-bottom: 60px;   */
    
   
    @media screen and (max-width: 480px){
        max-width: 100%;
        padding: 0px 0px 0px 0px;
        
        
        
    }
`;

export const TopLine = styled.p`
    color: #FFF;
    background-image: linear-gradient(to left, #ad1f1f,#1c25d4);
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 18px;
    margin-right: 300px;
    font-family: FontChilenaCondensed;
    border-radius: 4rem;
    text-align: center;
    padding-top: 6px;
    padding-bottom: 4px;
    width: 200px;

    @media screen and (max-width: 480px){
        background-image: linear-gradient(to left, #ad1f1f,#1c25d4);
        width: 180px;
        
    }
`;

export const Heading = styled.h1`
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: #dcfcf3;
     font-family: FontChilenaChilenaItalicBoldCondensed;
     
     @media screen and (max-width: 480px) {
        color: #FFF;
        font-size: 32px;
     }
`;

export const Subtitle = styled.p`
    max-width: 440px;
    margin-bottom: 15px;
    font-size: 18px;
    text-align: justify;
    color: ${({ darkText }) => (darkText ? '#010606' : '#fff') };
    font-family: FontChilenaFina;
    white-space: pre-wrap;
    line-height: 1.3;
    letter-spacing: -0.5px;
    
    @media screen and (max-width: 480px){
        max-width: 300px;
        color: #FFF;
    } 
`;

export const BtnWrap = styled.div`
    display: flex;
    justify-content: flex-start;
`;

export const  ImgWrap = styled.div`
    max-width: 555px;
    height: 100%;  
    overflow: hidden;
    border-radius: 0rem;
    filter: saturate(150%);

    @media screen and (max-width: 768px) {
    max-width: 100%;
  } 
`;

export const Image = styled.img`
    width: 100%;
    margin: 0 0 10px 0;
    padding-right: 0;   
    border-radius: 3rem;
    filter: saturate(180%);

    @media screen and (max-width: 480px) {
    width: 100%;
    max-height: 350px;
  }
`;

export const H1 = styled.h2`
    font-size: 35px;
    margin:-1rem 0rem 1rem 0rem ;
    color: #dcfcf3;
    font-family: FontChilenaChilenaItalicBoldCondensed;

    @media screen and (max-width: 480px){
        color: #dcfcf3;
    }  
`;

export const BotonListado = styled(Link)`
     border-radius:50px;
     white-space: nowrap;
     padding: ${({big}) => (big ? '14px 48px' : '10px 38px') };
     font-size: ${({fontBig}) => (fontBig ? '20px' : '16px' ) };
     outline: none;
     border: none;
     cursor: pointer;
     display: flex;
     justify-content: center;
     align-items: center;
     transition: all 0.8s ease;
     background-color: #DCFCF3;
     color: #000;

     &:hover{
         transform: scale(1.1);
         color: #fff;
         background-color: #00CD67;
         }
 `;