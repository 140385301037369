import {React, useState} from "react";
import useFetchData from '../Hooks/userFetchData';
import ReactMarkdown from 'react-markdown';
import {
    InfoContainer,
    InfoWrapper,
    InfoRow,
    Column1,
    Column2,
    TexWrapper,
    TopLine,
    Heading,
    Subtitle,
    BtnWrap,
    ImgWrap,
    H1,
    BotonListado,
  } from "./InfoElements";
  import ImageCarousel  from '../CarrouselForReactJs/ImageCarousel';
  
  // setButtonIndex

function Front(
   ) {
  const [buttonIndex,] = useState(10);
  const { loading, result, error } = useFetchData(
    'https://strapi-133505-0.cloudclusters.net/api/frontend-chilenter-firts-pages?populate=*', {cache: 'no-cache'}
    
  );
  

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error...</p>;

  return (
      <>
      {result.data.map((post, id) => {
          const baseUrl = "https://strapi-133505-0.cloudclusters.net";
          const title = post.attributes.Area;
          const subtitle = post.attributes.Subtitulo;
          const topLine = post.attributes.Titulo;
          const description = post.attributes.Contenido;
          const image = post.attributes.Imagenes.data.map(item => ({
            url: baseUrl + (item.attributes.formats.large?.url || item.attributes.url)
          }));          

          return (
            <InfoContainer key={id} id={`Front-${id}`}> 
              <InfoWrapper>
                <InfoRow>
                  <Column1>
                    <TexWrapper>
                      <TopLine>{title}</TopLine>
                      <Heading>{topLine}</Heading>
                      <H1> {subtitle} </H1>
                      <Subtitle>
                        <ReactMarkdown>{description}</ReactMarkdown>
                      </Subtitle>
                      <BtnWrap>
                        {id === buttonIndex && (
                          <BotonListado
                            to="/pdf"
                            target="_Blank"
                            style={{ textDecoration: "none"}}
                          >
                            Listado
                          </BotonListado>
                        )}
                      </BtnWrap>
                    </TexWrapper>
                  </Column1>
                  <Column2>
                    <ImgWrap>
                      <ImageCarousel images={image} />
                    </ImgWrap>
                  </Column2>
                </InfoRow>
              </InfoWrapper>
            </InfoContainer>
          );
        })}
    </>
  );
}

export default Front;
