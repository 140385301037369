import React from 'react';
import { SidebarContainer, 
    Icon, 
    CloseIcon,
    SidebarWrapper,
    SidebarMenu, 
    SideBtnWrap,
    SidebarButton,
    SidebarLinkS,
    SidebarLink 
} from './SidebarElements';

const Sidebar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
            <CloseIcon/>
        </Icon>
        <SidebarWrapper>
            <SidebarMenu>
                <SidebarLinkS to='/'>Página Inicial</SidebarLinkS>              
                <SidebarLinkS to='/noticias'>Noticias</SidebarLinkS>
            </SidebarMenu>
            <SideBtnWrap>
                <SidebarButton>
                    <SidebarLink to="footerTwo" onClick={toggle}>¡Contáctanos!</SidebarLink>
                </SidebarButton>
            </SideBtnWrap>
        </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar;
