import useFetchData from '../Hooks/userFetchData';
import { PostNewsContainer, PostNewsH1,  ImageText, ContentAll,  Description, Data, PostNewsWrapper, CardPost, Link,H2 } from './PostNewsElementsFirstLook';
import TextTruncate from 'react-text-truncate';


function PostNews() {
  const { loading, result, error } = useFetchData(
    'https://strapi-133505-0.cloudclusters.net/api/fuentes?populate=*', {cache: 'no-cache'}
    
  );

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error...</p>;

  const randomResult = result.data.sort(() => Math.random() - 0.5);

  return (
    <>
        <H2> Temas relacionados <hr/> </H2>
      {randomResult.slice(0, 4).map((post, id) => {
        const baseUrl = "https://strapi-133505-0.cloudclusters.net";
        const image = post.attributes.Image.data.map(item => baseUrl + item.attributes.formats.small.url);
        const firstImageUrl = image[0];
        const tittle = post.attributes.TitleForPostNews;
        const DataPost = post.attributes.Data;
        // const TextContent = post.attributes.Content;
        const PostDescription = post.attributes.Description;
        return (  
            <PostNewsContainer key={id} id='PostNew' >
              <Link href={`/noticias/${post.id}/${tittle}`} passHref style={{ textDecoration: 'none', color: '#000' }}>
                <PostNewsWrapper>
                  <CardPost>
                    <ImageText src={firstImageUrl} alt="first image" />
                  </CardPost>
                  <CardPost>
                    <ContentAll>
                      <PostNewsH1>{tittle}</PostNewsH1>
                      <TextTruncate
                          style={{ color:'#5A5A5A',fontSize:'0.9rem' }}
                          key='item-first'
                          line={4}
                          element="p"
                          truncateText='...'
                          text={PostDescription}
                        />
                      <Description></Description>
                      <Data>{DataPost} | Chile</Data>
                    </ContentAll>
                  </CardPost>
                </PostNewsWrapper>
              </Link>
          </PostNewsContainer>
        );
      })}
    </>
  );
}

export default PostNews;