import React from "react";
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TexWrapper,
  TopLine,
  Heading,
  Subtitle,
  ImgWrap,
  Image
} from "./DirectorioElements";
import useFetchData from '../Hooks/userFetchData';

const DirectoryBackend = ({
  lightBg,
  imgStart,
  lightText,
  darkText,
}) => {
  const { loading, result, error } = useFetchData(
    'https://strapi-133505-0.cloudclusters.net/api/directorio2s?populate=*', {cache: 'no-cache'}
  );

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error...</p>;

  const baseUrl = "https://strapi-133505-0.cloudclusters.net";
  const listItems = result.data.map((post, id) => {
    const cargo = post.attributes.Cargo;
    const integrante = post.attributes.Integrante;
    const description = post.attributes.Contenido;
    const image = post.attributes.Imagenes.data.map(item => ({
      url: baseUrl + (item.attributes.formats.large?.url || item.attributes.url)
    }));
  
    return (
      <InfoContainer key={id} ID='ND'  lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TexWrapper>
                <TopLine> {cargo} </TopLine>
                <Heading lightText={lightText}> {integrante} </Heading>
                <Subtitle darkText={darkText}> {description} </Subtitle>
              </TexWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Image src={image[0].url} alt='alt'/>
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    );
  });

  return (
    <>
      {listItems}
    </>
  );
};

export default DirectoryBackend;