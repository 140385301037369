import styled from "styled-components";

export const TextTruncate = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #5A5A5A;
`;

export const PostNewsContainer = styled.div`
  background-color: #fff;
  border-bottom: 0px solid #e5e5e5;
  padding: 1.5rem 1.5rem 0.5rem;
  display: inline-block; 
  width: 39%; 
  height:92%;
  margin: 0rem -18rem 0rem 15.5rem;

  @media screen and ( max-width: 480px ) {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 1rem 0rem 0rem 0rem;
  }

`;

export const PostNewsWrapper = styled.div`
  background-color: #fff;
  border-radius: 0.625rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.12),
    0 0.25rem 0.5rem rgba(0, 0, 0, 0.24);
  display: flex; 
  overflow: hidden;
  transition: 0.1s ease-in-out;
  width: 90%;
  max-height: fit-content;
  align-items: stretch;
  justify-content: center;

  &:hover {
    transform: scale(1.01);
    cursor: pointer;
  }

  @media screen and (max-width: 480px) {
  display: flex; 
  width: 100%;
  flex-direction: column;  

  &:hover {
    transform: scale(1.01);
    cursor: pointer;
  }
}
`;

export const CardPost = styled.div`
  flex: 1;
  display: flex;
  margin-bottom: -1rem;
  width: 100%;
  height: 100%;
`;

export const ImageText = styled.img`
  border-radius: 0.625rem 0 0 0.625rem;
  height: 70%;
  object-fit: cover;
  width: 100%;
  filter: saturate(170%);
  max-height: auto; 

  @media screen and (max-width: 480px) {
    border-radius: 0.625rem 0.625rem 0 0;
    max-height: initial;
  }
`;

export const ContentAll = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem 1rem 1.2rem 1rem;
  width: 100%;

  @media screen and (max-width: 480px) {
    padding: 0.75rem;
  }
`;

export const PostNewsH1 = styled.h1`
  color: #1d1d1d;
  font-size: 0.8rem;
  font-weight: 800;
  line-height: 1.2;
  margin: 0rem 0rem 0.3rem 0rem;

  &:hover{
    color: #426fc9;
  }

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const Description = styled.h2`
  color: #5A5A5A;
  font-size: 0.5rem;
  font-weight: 400;
  line-height: 1.2;
  margin: 0;
`;

export const PostImage = styled.img`
  border-radius: 0.625rem;
  height: 80%;
  object-fit: cover;
  width: 80%;
  
  @media screen and (max-width: 480px) {
    
  }
`;

export const Data = styled.p`
  font-size: 0.7rem;
  font-weight: 400;
  margin-top: 0.2rem;
  color:#B80000;
  font-style: oblique;
`;

export const DataOne = styled.p`
  font-size: 0.7rem;
  font-weight: 400;
  margin-top: 0.7rem;
  color:#B80000;
  font-style: oblique;
`;


export const Link = styled.a`
  width: 100%;
  height: auto;
`;
export const H2 = styled.h1`
  font-size: 2rem;
  font-style: bold;
  color: #141414;
  margin: 1rem 2rem 2rem 17rem;

  & hr {
    border: none;
    border-top: 2px solid #000;
    margin-top: -15px;
    margin-bottom: 20px;
    margin-left: 15rem;
    width: 65%;
  }

  @media screen and ( max-width: 480px ) {
  font-size: 2rem;
  font-style: bold;
  color: #141414;
  margin: 1rem 2rem 2rem 1rem;

  & hr {
    border: none;
    border-top: 2px solid #000;
    margin-top: 0px;
    margin-bottom: 20px;
    margin-left: 0rem;
    width: 71%;
  }   
}
  
`;

