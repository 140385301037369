import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useFetchData from '../Hooks/userFetchData';
import { Wrap, Title, Subtitle, Description, Image, Quote,Data, InfoImage, Autor, NewsChilenter, WrapAll, Localitation, Over,} from './Ishowyouelements';
import NavbarNews from '../NavbarNewsInto';
import Footer from '../FooterNews';
import Sidebar from '../SidebarNews/index';
import { Helmet } from 'react-helmet';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ShareButton from '../ShareButton/ShareButton';
import Relatives from '../NewsRelatives/PostNewsFirstLook';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import Avatar from '../../images/favicon.png';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

const Ishowyou = () => {
  const { id, tittle } = useParams();
  const [uniqueNews, setUniqueNews] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const { loading, result, error } = useFetchData(
    `https://strapi-133505-0.cloudclusters.net/api/destacadoss/${id}?populate=*`
  );

  useEffect(() => {
    if (result) {
      setUniqueNews(result.data);
      
    }
  }, [result]);
  console.log(result)

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error...</p>;
  }

  const baseUrl = 'https://strapi-133505-0.cloudclusters.net';

  const components = {
    blockquote: ({ children }) => {
      return <Quote>{children}</Quote>;
    },
  };
  

  return (
    <div>
      <FloatingWhatsApp 
        phoneNumber='+56950475565'
        accountName='Chilenter Noticias'
        statusMessage='Normalmente responde en 7min'
        chatMessage='¡Hola!  Bienvenido a la sección de Noticias 📊 📈 📉 ¿Cómo  puedo  ayudarte?😉 '
        allowClickAway= {true}
        allowEsc={true}
        notification={false}
        notificationSound={false}
        notificationLoop='3'
        avatar={Avatar}
        placeholder='Escribe un mensaje'
        darkMode={false}
      />
      <Helmet>
        <title>{tittle}</title>
      </Helmet>
      
      {uniqueNews ? (
        <>
          <Sidebar isOpen={isOpen} toggle={toggle} />
          <NavbarNews toggle={toggle} />
          <Over>          
          <Wrap>
            <Localitation> {uniqueNews.attributes.Localitation} <hr/> </Localitation>
            <Title>{uniqueNews.attributes.Titulo}</Title>
            <Data><FontAwesomeIcon icon={faClock} /> {uniqueNews.attributes.Data}</Data>
            <ShareButton/>
            <br></br>
            <Image
              src={uniqueNews.attributes.Image.data.map(
                (item) => baseUrl + item.attributes.url
              )}
              alt='Images'
              />
           <WrapAll>
            <InfoImage>| {uniqueNews.attributes.PhotoDescription} </InfoImage>
            <br/>
            <Autor>Autor {uniqueNews.attributes.Author} </Autor>
            <NewsChilenter>Chilenter Noticias <hr/></NewsChilenter>
            <Subtitle>{uniqueNews.attributes.Description}</Subtitle>
            <Description> <ReactMarkdown components={components} rehypePlugins={[rehypeRaw]} >{uniqueNews.attributes.Content}</ReactMarkdown> </Description> 
            </WrapAll>
          </Wrap>
          <Relatives />
          </Over>
          <Footer />
        </>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default Ishowyou;