import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './pages';
import Pdf from './Components/Pdf/Pdf';
import Noticias from './pages/PageTwo';
import ishowyou from './Components/Ishowyou/Ishowyou';
import IshowyouFrontPageNews from './Components/IshowyouFrontPageNews/IshowyouFrontPageNews';
import ishowyouNewsMore from './Components/Ishowyou Newsmore/Ishowyou';

function App() {
  return (
    <Router>
      <Switch>
        <Route path='/' component={Home} exact/>
        <Route path='/pdf' component={Pdf} exact/>
        <Route path='/noticias' component={Noticias} exact/>
        <Route path="/noticias/:id/:tittle" component={ishowyou} />
        <Route path="/destacado/front-page-news/:id/:tittle" component={IshowyouFrontPageNews} />
        <Route path="/destacados/news-more/:id" component={ishowyouNewsMore} />
      </Switch>
    </Router>
  );
}

export default App;
