import React, { useState, useEffect } from 'react';
import useFetchData from '../Hooks/useStrapiData';
import { Wrap, Title, Subtitle, Video, Description, Image,Quote ,Data, InfoImage, Autor, NewsChilenter, WrapAll, Over, Localitation  } from './IshowyouIshowyouFrontPageNewselements';
import Footer from '../FooterNews';
import { Helmet } from 'react-helmet';
import SideBar from '../SidebarNews';
import NavbarNews from '../NavbarNewsInto/index';
import ShareButton from '../ShareButton/ShareButton';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import Avatar from '../../images/favicon.png';
import Relatives from '../NewsRelatives/PostNewsFirstLook';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';


const IshowyouFrontPageNews = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const { loading, result, error } = useFetchData(
    `https://strapi-133505-0.cloudclusters.net/api/front-page-newses?populate=*`
  );

  useEffect(() => {
    if (result) {
    }
  }, [result]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error...</p>;
  }
  console.log(result.data);
  const title = result.data[0].attributes.Title;
const data = result.data[0].attributes.Date;
const baseUrl = 'https://strapi-133505-0.cloudclusters.net';
const mediaData = result.data[0].attributes.Media.data[0].attributes;
const mediaUrl = baseUrl + mediaData.url;
const fileExtension = mediaData.ext.toLowerCase();
const image = result.data[0].attributes.Media.data[0].attributes.url;
const photoDescription = result.data[0].attributes.PhotoDescription;
const autor = result.data[0].attributes.Author;
const description = result.data[0].attributes.Description;
const content = result.data[0].attributes.Content;
const local = result.data[0].attributes.Localitation;

const components = {
  blockquote: ({ children }) => {
    return <Quote>{children}</Quote>;
  },
};


  
  return (
    <div>
      <FloatingWhatsApp 
        phoneNumber='+56950475565'
        accountName='Chilenter Noticias'
        statusMessage='Normalmente responde en 7min'
        chatMessage='¡Hola!  Bienvenido a la sección de Noticias 📊 📈 📉 ¿Cómo  puedo  ayudarte?😉 '
        allowClickAway= {true}
        allowEsc={true}
        notification={false}
        notificationSound={false}
        notificationLoop='3'
        avatar={Avatar}
        placeholder='Escribe un mensaje'
        darkMode={false}
      />
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {result && (
        
        <>
          <SideBar isOpen={isOpen} toggle={toggle} />
          <NavbarNews toggle={toggle} />
          <Over>
          <Wrap>
            <Localitation>{local}<hr/></Localitation>
          <Title>{title}</Title>
          <Data><FontAwesomeIcon icon={faClock} /> {data}</Data>
          <ShareButton/>
          <br></br>
          {fileExtension === '.mp4' || fileExtension === '.webm' ? (
                  <Video src={mediaUrl} controls muted={false} autoPlay={true}/>
                ) : fileExtension === '.jpg' || fileExtension === '.jpeg' || fileExtension === '.png' ? (
                  <Image src={baseUrl + image} alt='image' />
                ) : (
                  <p>Tipo de contenido no compatible</p>
                )}

          <WrapAll>
            <InfoImage>| {photoDescription} </InfoImage>
            <br/>
            <Autor>Autor {autor} </Autor>
            <NewsChilenter>Chilenter Noticias <hr/></NewsChilenter>
            <Subtitle>{description}</Subtitle>
            <Description> <ReactMarkdown components={components} rehypePlugins={[rehypeRaw]} >{content}</ReactMarkdown> </Description> 
            </WrapAll>
          </Wrap>
          <Relatives />
          </Over>
          <Footer />
        </>
      )}
    </div>
  );
};

export default IshowyouFrontPageNews;