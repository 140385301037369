import styled from "styled-components";

export const NewsWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 68%;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 2rem -18rem 1rem 16.6rem;


    @media screen and (max-width: 480px) {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    margin: 2rem 0rem 0rem 0rem;
    }

    @media screen and (max-width: 1280px) {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    margin: 2rem 0rem 0rem 0rem;
    }
  `;

export const LargeNews = styled.div`
  flex-basis: 65%;
  padding: 0 10px;
  transition: 2ms ease-in-out ;

  @media screen and ( max-width: 480px) {
    width: 138%;
    margin-left: 0.5rem;
    
  }

  img {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 32px;
    margin-bottom: 10px;
    &:hover{
      color: #426fc9;
    }
  }

  p {
    font-size: 20px;
    margin-bottom: 20px;
  }
`;

export const SmallNews = styled.div`
  flex-basis: 30%;
  padding: 0 10px;
  margin-bottom: 20px;

  @media screen and (max-width: 480px) {
    margin-bottom: 1.5rem;
    margin-left: 0.5rem;
  }

  img {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }

  h3 {
    font-size: 24px;
    margin-bottom: 10px;
    &:hover{
      color: #426fc9;
    }
  }

  p {
    font-size: 16px;
    margin-bottom: 10px;
  }
`;