import styled from "styled-components";

export const TextTruncate = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #5A5A5A;
`;

export const PostNewsContainer = styled.div`
  background-color: #fff;
  border-bottom: 1px solid #e5e5e5;
  margin: 0 0;
  padding: 1.5rem 1.5rem 0.5rem;
  display: inline-block; 
  width: 35%;
  height: auto;
  margin: 1rem -18rem 1rem 17.5rem;

  @media screen and (max-width: 480px) {
    background-color: #fff;
    border-bottom: 1px solid #e5e5e5;
    margin: 0 0;
    padding: 1.5rem 1.5rem 0.5rem;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%; 
    margin: 1rem 0rem 1rem 0rem;
  }

  @media screen and (max-width: 820px) {
    background-color: #fff;
    border-bottom: 1px solid #e5e5e5;
    margin: 0 0;
    padding: 1.5rem 1.5rem 0.5rem;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%; 
    margin: 1rem 0rem 1rem 0rem;
  }

  @media screen and (max-width: 1024px) {
    background-color: #fff;
    border-bottom: 1px solid #e5e5e5;
    margin: 0 0;
    padding: 1.5rem 1.5rem 0.5rem;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%; 
    margin: 1rem 0rem 1rem 0rem;
  }
  
`;

export const PostNewsWrapper = styled.div`
  background-color: #fff;
  border-radius: 0.625rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.12),
    0 0.25rem 0.5rem rgba(0, 0, 0, 0.24);
  display: flex; 
  overflow: hidden;
  transition: 0.1s ease-in-out;
  width: 100%;
  max-height: fit-content;
  align-items: stretch;
  justify-content: center;
  margin: 0 auto; 

  &:hover {
    transform: scale(1.01);
    cursor: pointer;
  }

  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
`;

export const CardPost = styled.div`
  flex: 1;
  display: flex;
`;

export const ImageText = styled.img`
  border-radius: 0.625rem 0 0 0.625rem;
  height: 100%;
  object-fit: cover;
  width: 100%;
  filter: saturate(170%);
  max-height: auto; 

  @media screen and (max-width: 480px) {
    border-radius: 0.625rem 0.625rem 0 0;
    max-height: initial;
  }
`;

export const ContentAll = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem 1rem 1.2rem 1rem;
  width: 100%;

  @media screen and (max-width: 480px) {
    padding: 0.75rem;
  }
`;

export const PostNewsH1 = styled.h1`
  color: #1d1d1d;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.2;
  margin: 0rem 0rem 0.5rem 0rem;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const Description = styled.h2`
  color: #5A5A5A;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.2;
  margin: 0;
`;

export const PostImage = styled.img`
  border-radius: 0.625rem;
  height: 100%;
  object-fit: cover;
  width: 100%;
  @media screen and (max-width: 480px) {
    
  }
`;

export const Data = styled.p`
  color: #5c5c5c;
  font-size: 0.805rem;
  font-weight: 400;
  margin: 0.5rem 0rem 0rem 0rem;
  text-transform: uppercase;
`;

export const Link = styled.a`
  width: 100%;
  height: auto;
`;
export const H2 = styled.h1`
  font-size: 2rem;
  font-style: bold;
  color: #141414;
  margin:1rem 2rem 2rem 18.6rem;

  & hr {
    border: none;
    border-top: 2px solid #000;
    margin-top: -15px;
    margin-bottom: 20px;
    margin-left: 18rem;
    width: 62.8%;
  }

  @media screen and (max-width: 480px) {
    font-size: 2rem;
    font-style: bold;
    color: #141414;
    margin:1rem 2rem 2rem 1.4rem;
    
    & hr {
    border: none;
    border-top: 2px solid #000;
    margin-top: -0.5px;
    margin-bottom: 20px;
    margin-left: 0rem;
    width: 87.5%;
  }
}

@media screen and (max-width: 820px) {
    font-size: 2rem;
    font-style: bold;
    color: #141414;
    margin:1rem 2rem 2rem 1.4rem;
    
    & hr {
    border: none;
    border-top: 2px solid #000;
    margin-top: -0.5px;
    margin-bottom: 20px;
    margin-left: 0rem;
    width: 87.5%;
  }
}

@media screen and (max-width: 1024px) {
    font-size: 2rem;
    font-style: bold;
    color: #141414;
    margin:1rem 2rem 2rem 1.4rem;
    
    & hr {
    border: none;
    border-top: 2px solid #000;
    margin-top: -0.5px;
    margin-bottom: 20px;
    margin-left: 0rem;
    width: 87.5%;
  }
}
`;

