import useFetchData from '../Hooks/userFetchData';
import { PostNewsContainer, PostNewsH1,  ImageText, ContentAll, DataOne, Description, Data, PostNewsWrapper, CardPost, Link,H2 } from './PostNewsElementsFirstLook';
import TextTruncate from 'react-text-truncate';


function PostNews() {
  const { loading, result, error } = useFetchData(
    'https://strapi-133505-0.cloudclusters.net/api/fuentes?populate=*', {cache: 'no-cache'}
    
  );

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error...</p>;

  const sortedResult = result.data.sort(
    (a, b) => new Date(b.attributes.Data) - new Date(a.attributes.Data)
  );
  
  // console.log (sortedResult)

  return (
    <>
        <H2>Ùltimas noticias <hr/> </H2>
      {sortedResult.map((post, id) => {
        const baseUrl = "https://strapi-133505-0.cloudclusters.net";
        const image = post.attributes.Image.data.map(item => baseUrl + item.attributes.formats.small.url);
        const firstImageUrl = image[0];
        const tittle = post.attributes.TitleForPostNews;
        const DataPost = post.attributes.Data;
        const PostDescription = post.attributes.Description;
        const localitation = post.attributes.Localitation;
        
        return (  
            <PostNewsContainer key={id} id='PostNew'>
              <Link href={`/noticias/${post.id}/${tittle}`} passHref style={{ textDecoration: 'none', color: '#000' }}>
                <PostNewsWrapper>
                  <CardPost>
                    <ImageText src={firstImageUrl} alt="first image" />
                  </CardPost>
                  <CardPost>
                    <ContentAll>
                      <PostNewsH1>{tittle}</PostNewsH1>
                      <TextTruncate
                          style={{ color:'#5A5A5A',fontSize:'0.8rem' }}
                          key='item-first'
                          line={2}
                          element="p"
                          truncateText='...'
                          text={PostDescription}
                        />
                      <Description></Description>
                      <Data> {localitation} </Data>
                      <DataOne> {DataPost} </DataOne>
                    </ContentAll>
                  </CardPost>
                </PostNewsWrapper>
              </Link>
          </PostNewsContainer>
        );
      })}
    </>
  );
}

export default PostNews;