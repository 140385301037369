import React from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { 
    FooterContainer,
    FooterWrap,
    FooterLinksContainer,
    FooterLinksWrapper,
    FooterLinkItems,
    FooterLinkTitle,
    FooterLink,
    SocialMedia,
    SocialMediaWrap,
    SocialLogo,
    WebsiteRights,
    SocialIcons,
    SocialIconLink,
    Chome,
    Mc,
    Mc1,
    Mc2,
    Image,
    Img,
    Contact
} from './FooterElements';

import HOME from '../../images/ChilenterWhiteNavbar.png';
import MC1 from '../../images/marca.png';
import MC2 from '../../images/LG.jpg';
import MC3 from '../../images/IT.jpg';
import MC4 from '../../images/T.jpg';
import MC5 from '../../images/LB.jpeg';

import Iconfb from '../../images/fb.png';
import Iconig from '../../images/ig.png';
import Icontw from '../../images/tw.png';
import Iconyt from '../../images/yt.png';
import Iconin from '../../images/in.png';


const Footer = () => {
    const toggleHome = () =>{
        scroll.scrollToTop();
      };

  return (
    <FooterContainer>
        <FooterWrap>
            <FooterLinksContainer>
                <FooterLinksWrapper>
                    <FooterLinkItems>
                        <FooterLinkTitle> Tecnología para todos </FooterLinkTitle>
                            <FooterLink> Aspiramos a construir 
                                una sociedad donde la tecnología sea un 
                                medio efectivo y sustentable para la inclusión 
                                y desarrollo de las personas. 
                            </FooterLink>
                    </FooterLinkItems>     
                    <FooterLinkItems>
                        <FooterLinkTitle> Contáctanos </FooterLinkTitle>
                            <FooterLink> Dirección: Compañía 4365, Quinta Normal Santiago de Chile. </FooterLink>
                            <Contact> <p>Para contactarnos puedes escribirnos vía WhatsApp</p> </Contact>
                            <FooterLink> Email: contacto@chilenter.cl </FooterLink>
                    </FooterLinkItems>
                </FooterLinksWrapper>
                <FooterLinksWrapper>
                     <FooterLinkItems>                            
                            <Mc1 src={MC1}></Mc1>
                            <Mc target="_blank" href='https://www.portaltransparencia.cl/PortalPdT/ingreso-sai-v2?idOrg=58501'>
                               <Img  src={MC3}/>
                            </Mc>
                            <Mc  target="_blank" href='http://transparencia.chilenter.com'>
                                <Img src={MC4}/>
                            </Mc>
                            <Mc  target="_blank" href='https://www.leylobby.gob.cl/instituciones/FU007'>
                                <Img src={MC5}/>
                            </Mc>
                    </FooterLinkItems>
                    <FooterLinkItems to='/'>
                        <FooterLinkTitle to='/'>  </FooterLinkTitle>
                            <Mc2 target="_blank" href="https://www.gob.cl" id='footerTwo'>
                                <Img src={MC2}/>    
                            </Mc2>       
                        </FooterLinkItems>  
                </FooterLinksWrapper>
            </FooterLinksContainer>
             <SocialMedia to='/'>
                <SocialMediaWrap to='/'>
                    <SocialLogo to='#' onClick={toggleHome}>
                        <Chome src={HOME}></Chome> 
                    </SocialLogo >
                    <WebsiteRights>Chilenter © {new Date().getFullYear()} All rights reserved - Designed by Roniel Daboin.</WebsiteRights>
                    <SocialIcons>
                        <SocialIconLink href="https://www.facebook.com/fundacion.chilenter" target="_blank"
                            aria-label="Facebook"  >
                               <Image src={Iconfb}/>
                        </SocialIconLink>
                                               
                        <SocialIconLink href="https://www.instagram.com/chilenter_fundacion/" target="_blank"
                            aria-label="Instagram">
                                <Image src={Iconig}/>
                        </SocialIconLink>

                        <SocialIconLink href="https://twitter.com/Chilenter" target="_blank"
                            aria-label="Twitter">
                                <Image src={Icontw}/>
                        </SocialIconLink>
    
                        <SocialIconLink href="https://www.youtube.com/c/FundaciónChilenter" target="_blank"
                            aria-label="Youtube">
                                <Image src={Iconyt}/>
                        </SocialIconLink>

                        <SocialIconLink href="https://www.linkedin.com/company/fundación-chilenter/?originalSubdomain=cl" target="_blank"
                        aria-label="Linkedin">
                            <Image src={Iconin}/>
                       </SocialIconLink> 
                    </SocialIcons>
                </SocialMediaWrap>
             </SocialMedia>
        </FooterWrap>
    </FooterContainer>
  )
}

export default Footer;