import React, { useEffect } from "react";
import Listado from '../DocsPDFs/Listado.pdf'

const Pdfs = () => {
  useEffect(() => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      window.open(Listado, '_blank');
      window.close();
    }
  }, []);

  return (
    <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
      <object
        data={Listado}
        type='application/PDF'
        width='100%'
        height='100%'
      >
        <br />
      </object>
    </div>
  );
};

export default Pdfs;



// import React, { Component } from "react";
// import Listado from '../DocsPDFs/Listado.pdf'

// class Pdfs extends Component {

//     componentDidMount() {
//         if(typeof window.orientation !=='undefined'){
//             document.getElementById('PDF').click();
//             window.close();

//         }
//     }

//     render(){
//         return(
//             <div style={{position:'absolute', width:'100%', height:'100%'}} >
//                 <object
//                 data={Listado}
//                 type='application/PDF'
//                 width='100%'
//                 height='100%'
//                 >
//                    <br/> 

//                 <a href={Listado} id='PDF' 
//                     download='Listado.pdf'
//                 > </a>    
//                 </object>
//             </div>
//         );
//     }


    
// };

// export default Pdfs;