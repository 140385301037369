import React from 'react'; 
import useFetchData from '../Hooks/userFetchData'; 
import { 
    HeroContainer, 
    HeroBg, 
    VideoBg, 
    HeroContent, 
    HeroH1, 
    HeroP, 
} from './HeroElements';

const HeroSection = () => { 
  const { loading, result, error } = useFetchData(
    'https://strapi-133505-0.cloudclusters.net/api/hero-section-first-pages?populate=*', {cache: 'no-cache'}
  );

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error...</p>;

  const baseUrl = "https://strapi-133505-0.cloudclusters.net";
  const video = (baseUrl)+result.data[0].attributes.Video.data[0].attributes.url;
  const titulo = result.data[0].attributes.Titulo;
  const subtitulo = result.data[0].attributes.Subtitulo;
  console.log(result)

  return (
    <HeroContainer>
        <HeroBg>
            <VideoBg autoPlay loop muted 
            src={video} type='video/mp4'/>
        </HeroBg>
        <HeroContent>
            <HeroH1> {titulo} </HeroH1>
            <HeroP>
                    {subtitulo}
            </HeroP>
        </HeroContent>
    </HeroContainer>
   );
};

export default HeroSection;
