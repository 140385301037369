import React, {useEffect, useState} from 'react';
import {FaBars} from 'react-icons/fa'
import { IconContext } from 'react-icons/lib';
import { animateScroll as scroll} from 'react-scroll';

import { Nav, 
  NavbarContainer, 
  NavLogo, 
  MobileIcon, 
  NavMenu, 
  NavItem, 
  NavLinks, 
  Clogo,
  StyledLink
} from './NavbarElements';
import Logo  from '../../images/ChilenterWhiteNavbar.png';

const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false)

  const changeNav = () => {
    if(window.scrollY >=80){
      setScrollNav(true)
    } else {
      setScrollNav(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', changeNav)
  }, [] )

  const toggleHome = () =>{
    scroll.scrollToTop();
  }
  
  return (
    <>
        <IconContext.Provider value={{ color: '#fff'}}>
        <Nav scrollNav={scrollNav}>
            <NavbarContainer >
               <NavLogo onClick={toggleHome}> 
                <Clogo  duration={2500} src={Logo}></Clogo>
               </NavLogo> 
                <MobileIcon onClick={toggle}>
                  <FaBars />
                </MobileIcon>     
            </NavbarContainer> 
            <NavMenu >
                  <NavItem>
                    <NavLinks 
                    to="Front-0"
                    smooth={true} 
                    duration={2500} 
                    spy={true}
                    exact='true'
                    offset={-80}
                    >Economía Circular Sustentable</NavLinks>
                  </NavItem>
                  <NavItem>
                    <NavLinks 
                    to="FrontTwo-0"
                    smooth={true} 
                    duration={2500} 
                    spy={true}
                    exact='true'
                    offset={-80}
                    >Residuos que Recibimos</NavLinks>
                  </NavItem>
                  <NavItem>
                    <NavLinks 
                    to="FrontTwo-1"
                    smooth={true} 
                    duration={2500} 
                    spy={true}
                    exact='true'
                    offset={-80}
                    >Impacto Social</NavLinks>
                  </NavItem>
                  <NavItem>
                    <NavLinks
                    to="Directorio"
                    smooth={true} 
                    duration={2500} 
                    spy={true}
                    exact='true'
                    offset={-80}
                    >Nuestro Directorio</NavLinks>
                  </NavItem>
                  <NavItem>
                    <StyledLink to='/noticias' onClick={toggleHome}>Noticias</StyledLink>
                  </NavItem>
                  <NavItem>
                    <NavLinks 
                    to="footer2"
                    smooth={true} 
                    duration={5800} 
                    spy={true}
                    exact='true'
                    offset={-80}
                    >Contáctanos
                    </NavLinks>
                  </NavItem>
                </NavMenu>              
        </Nav>
      </IconContext.Provider>
    </>
  )
};

export default Navbar;