import styled from "styled-components";

export const PostNewsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 67%;
  margin: -1rem 2rem 5rem 17.3rem;

  @media screen and ( max-width: 480px ) {
    display: block;
    width: 100%;
    margin: 1rem 0rem 2.5rem 0rem;
  }
`;

export const PostNewsCard = styled.div`
  display: flex;
  width: 100%;
`;

export const ImageText = styled.img`
  max-width: 50%;
  max-height: 375px;
  object-fit: cover;
  filter: saturate(120%);

  @media screen and ( max-width: 480px ) {
    max-width: 100%;
    max-height: 375px;
    object-fit: cover;
    filter: saturate(110%);
    margin: 0rem 0rem 0rem 1rem;
  }
`;

export const Video = styled.video`
 max-width: 50%;
  max-height: 375px;
  object-fit: cover;
  filter: saturate(120%);

  @media screen and ( max-width: 480px ) {
    max-width: 100%;
    max-height: 375px;
    object-fit: cover;
    filter: saturate(110%);
    margin: 0rem 0rem 0rem 1rem;
  }
`

export const ContentAll = styled.div`
  padding: 1rem;
  width: 50%;
`;

export const PostNewsH1 = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #141414;
  
  &:hover{
      color: #426fc9;
    }

  @media screen and (max-width: 480px){
    font-size: 1.7rem;
  }
`;

export const Description = styled.p`
  margin-bottom: 1.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  width: 85%;
`;

export const Data = styled.p`
  font-size: 1.1rem;
  font-weight: 400;
  color:#B80000;
  font-style: oblique;
`;

export const DataOne = styled.p`
  font-size: 0.9rem;
  font-weight: 400;
  margin-top: 0.4rem;
  color:#B80000;
  font-style: oblique;
`;

export const H2 = styled.h1`
  font-size: 2rem;
  font-style: bold;
  color: #141414;

  & hr {
    border: none;
    border-top: 2px solid #141414;
    margin-top: -15px;
    margin-bottom: 20px;
    margin-left: 15rem;
    width: 67%;
  }
`;

export const PostNewsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  width: 100%;

  @media screen and ( max-width: 480px ) {
  display: flex;
  flex-direction: column;
  width: 90%;
  }
`;

export const PostNewsContent = styled.div`
  width: 50%;

  @media screen and ( max-width: 480px ) {
  width: 100%;
  margin: 0rem 0rem 0rem 1rem;
  }
`;

export const ReadMore = styled.div`
  margin-top: 2rem;
  color: #B80000;
  font-size: 1.3rem;
  font-style: oblique;
  width: 19%;
  overflow: hidden;
  padding-bottom: 0.2rem;

  border-bottom: 2px solid transparent;

  &:hover {
    cursor: pointer;
    border-bottom: 2px solid #B80000;
  }

  display: inline-block;
  max-width: 89px;
  height: auto;

  @media screen and ( max-width: 480px ) {
  margin-top: 2rem;
  color: #B80000;
  font-size: 1.3rem;
  font-style: oblique;
  width: 50%;
  overflow: hidden;
  padding-bottom: 0.2rem;

  border-bottom: 2px solid transparent;

  &:hover {
    cursor: pointer;
    border-bottom: 2px solid #B80000;
  }

  display: inline-block;
  max-width: 89px;
  height: auto;
  }
`;

export const Link = styled.a`
`;