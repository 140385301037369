import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Link as LinkS} from 'react-scroll';
import backgroundImg from '../../images/blue.jpg'
import backgroundImgTow from '../../images/green.jpg'

export const DateTime = styled.div`
    width: 100%;
    height: auto;
    margin: 3.5rem 1rem 1rem -10.8rem;
    font-size: 1rem;
    color: #fff;

    @media screen and (max-width: 480px) {
    width: 10%;
    height: auto;
    margin: 2.8rem 1rem 1rem -4.7rem;
    font-size: 0.1rem;
    color: #fff;  
    }
`;

export const H1 = styled.h1`
    font-size: 3rem;
    margin: 0rem 0rem 0rem 5rem;
    
    @media screen and (max-width: 480px) {
    margin: 1.2rem 0rem 0rem -1rem;
    font-size: 1.3rem;
    }  
`;

export const StyledLink = styled(Link)`
      color: #FFF;
      display: flex;
      align-items: center;
      text-align: center;
      text-decoration: none;
      padding: 0 3rem;
      margin-left: 0px;
      margin-right: 40px;
      height: 100%;;
      cursor: pointer;
      border-width: 1px;
      border-color: #fff;
      padding-left: 42px;
      border-bottom: none;
      border-left: none;
      border-top: none;
      border-right-style: inset;
      border-radius: 4rem;
      transition: all 0.05s ease-in-out;

      &:hover{  
            color: #d5e3d9;
            font-size: 15px;
        }
      
      &.active{
      border-width: 4px;
      border-color: #FFF;
      padding-left: 42px;
      border-bottom: none;
      border-left: none;
      border-top: none;
      border-radius: 10rem;
    }
`;

export const Nav = styled.nav`
        background: ${({ scrollNav }) => ( scrollNav ? `url(${backgroundImg})` : `url(${backgroundImgTow})` ) };
        height:${({ scrollNav }) => ( scrollNav ? "85px" : '110px' ) };
        background-repeat: no-repeat;
        background-size: cover;
        margin-top: -80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1rem;
        position: sticky;
        top: 0;
        z-index: 50;
        transition: 0.5s all ease-in-out;
        @media screen and (max-width: 960px) {
   
    ${({ isOpen }) => (isOpen ? 'height: 100vh;' : '')}
  }
        
    @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
        background: #1FB05A;
        background: ${({ scrollNav }) => ( scrollNav ? `url(${backgroundImg})` : `url(${backgroundImgTow})` ) };
    }
    
    @media screen and (max-width: 1289px) {
        transition: 0.8s all ease;
        background: #1FB05A;
        background: ${({ scrollNav }) => ( scrollNav ? `url(${backgroundImg})` : `url(${backgroundImgTow})` ) };
    }
`;

    
    
export const NavbarContainer = styled.div`
        display: flex;
        justify-content: space-between;
        height: 80px;
        z-index: 1;
        width: 100%;
        padding: 0 24px;
        max-width: 1100px;     
`;

export const NavLogo = styled.div`
        color:#fff;
        justify-self: flex-start; 
        cursor: pointer;    
        display: flex;
        text-decoration: none;  
        
        @media screen and (max-width: 480px) {
        color:#fff;
        justify-self: flex-start; 
        cursor: pointer;    
        display: flex;
        text-decoration: none;
        margin: 0.3rem 0rem 0rem -5rem;
        }
`;

export const MobileIcon = styled.div`
        display: none;
         
    @media screen and (max-width: 768px){
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #fff;
    }

    @media screen and (max-width: 980px){
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #fff;
    }

    @media screen and (max-width: 1365px){
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #fff;
    }
`;

export const NavMenu = styled.ul`
        display: flex;
        align-items: center;
        list-style: none;
        text-align: center;
        margin-left: -30rem;
        z-index: 2;
        
    @media screen and (max-width: 768px){
           display: none;
       }

    @media screen and (max-width: 980px){
           display: none;
       }

       @media screen and (max-width: 1365px) {
        display: none;
    }
`;

export const NavItem = styled.li`
        height: 80px;
    `;

export const NavLinks = styled(LinkS)`
        color: #FFF;
        display: flex;
        align-items: center;
        text-align: center;
        text-decoration: none;
        padding: 0 3rem;
        margin-left: 0px;
        margin-right: 40px;
        height: 100%;;
        cursor: pointer;
        border-width: 1px;
        border-color: #fff;
        padding-left: 42px;
        border-bottom: none;
        border-left: none;
        border-top: none;
        border-right-style: inset;
        border-radius: 4rem;
        transition: all 0.2s ease-in-out;

        &:hover{  
            color: #d5e3d9;
            font-size: 18px;
        }
        
        &.active{
        border-width: 4px;
        border-color: #FFF;
        padding-left: 42px;
        border-bottom: none;
        border-left: none;
        border-top: none; 
        border-radius: 10rem;   
       }
`;

export const NavBtn = styled.nav`
        display: flex;
        align-items: center;

        @media screen and (max-width: 768px) {
        display: none;
        }
`;

export const NavBtnLink = styled(LinkS)`
        border-radius: 50px;
        background: #01bf71;
        white-space: nowrap;
        padding: 10px 22px;
        color: #010606;
        font-size: 16px;
        outline: none;
        border: none;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        text-decoration: none;

        &:hover{

            background: #fff;
            color: #010606;
        }
`;
    
    export const Clogo = styled.img`
    width: 250px;
    height: 90px;
    margin-top: -6px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: 0px;
    padding-right: 20px;
    filter: saturate(200%);

    @media screen and ( max-width: 800px ) {
    display: flex;
    align-items: center;
    height: 80px;
    width:auto;
    margin-left: 70px;  
    }
`;

export const LogoText = styled.div`
  display: ${({ scrollNav }) => (scrollNav ? 'block' : 'none')};
  margin-left: 1rem;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
`;

export const NavLink = styled.div`

`;

