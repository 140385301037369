import React from 'react';
import { SidebarContainer, 
    Icon, 
    CloseIcon,
    SidebarWrapper,
    SidebarMenu, 
    SidebarLink,
    SideBtnWrap,
    SidebarBoton,
    SidebarLinkS 
} from './SidebarElements';

const SideBar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onclick={toggle}>
            <CloseIcon/>
        </Icon>
        <SidebarWrapper>
            <SidebarMenu>
                <SidebarLink to="economia" onClick={toggle}>Economía Circular Sustentable</SidebarLink>
                <SidebarLink to="residuos" onClick={toggle}>Residuos que Recibimos</SidebarLink>
                <SidebarLink to="imsd" onClick={toggle}>Impacto Social </SidebarLink>
                <SidebarLink to="DirectorioID" onClick={toggle}>Nuestro Directorio </SidebarLink>              
                <SidebarLinkS to='/noticias'>Noticias </SidebarLinkS>
            </SidebarMenu>
            <SideBtnWrap>
                <SidebarBoton>
                <SidebarLink to="footer2" onClick={toggle}>¡Contáctanos! </SidebarLink>
                </SidebarBoton>
            </SideBtnWrap>
        </SidebarWrapper>
    </SidebarContainer>
  )
}

export default SideBar;