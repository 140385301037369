import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styled from "styled-components";

const CarouselContainer = styled.div`
  width: 100%;

  @media screen and (max-width: 768px) {
    .carousel .slide img {
      width: 100%;
      height: auto;
    }
  }
`;

const ImageCarousel = ({ images }) => {
  return (
    <CarouselContainer>
      <Carousel showThumbs={false} showStatus={false} infiniteLoop autoPlay interval={2800}>
        {images.map((image, index) => (
          <div key={index}>
            <img src={image.url} alt={image.name} />
          </div>
        ))}
      </Carousel>
    </CarouselContainer>
  );
};

export default ImageCarousel;













// import React from "react";
// import { Carousel } from 'react-responsive-carousel'; 
// import 'react-responsive-carousel/lib/styles/carousel.min.css';
// import { Slideshow, Slide, TextoSlide } from '../NewCarousel/Slideshow';


// const ImageCarousel = ({ images }) => {
//     console.log(images)
//     if (images.length === 1) {
//         return (
//             <Slideshow>
//                 <Slide>
//                     <img src={images[0].url} alt={images[0].name} style={{ height: '100%', width: '500px' }}/>
//                     <TextoSlide>{images[0].name}</TextoSlide>
//                 </Slide>
//             </Slideshow>
//         );
//     } else {
//         return (
//             <Slideshow>
//                 {images.map((image, index) => (
//                     <Slide key={index}>
//                         <img src={image.url} alt={image.name} style={{ height: '100%', width: '500px' }}/>
//                         <TextoSlide>{image.name}</TextoSlide>
//                     </Slide>
//                 ))}
//             </Slideshow>
//         );
//     }
// };

// export default ImageCarousel;

