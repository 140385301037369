import styled from "styled-components";

export const InfoContainer = styled.div`
  color: #fff;
  background-image: linear-gradient(to left, #CCFFE9,#e6fcee);
  padding: 20px 0px 20px 55px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 480px) {
    padding: 100px 0;
  }
`;

export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  height: 100%;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 24px;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

`;

export const InfoRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 1110px;
  align-items: center;
  
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? "'col2 col1'" : "'col1 col2'"};

  @media screen and (max-width: 768px) {
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? "'col1' 'col2'" : "'col1' 'col2'"};
  }
`;

export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
  
`;

export const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
`;

export const TexWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
  text-align: start;
  
  @media screen and (max-width: 768px) {
    max-width: 100%;
    margin-top: -50px;
  }
`;

export const TopLine = styled.p`
  color: #72ba8c;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
  font-family: FontChilenaCondensed;

  @media screen and (max-width: 480px) {
    letter-spacing: 1.5px;
  }
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 36px;
  line-height: 1.1;
  font-weight: 600;
  color: #54b076;
  font-family: FontChilenaChilenaItalicBoldCondensed;

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const Subtitle = styled.p`
  max-width: 440px;
  margin-bottom: 35px;
  text-align: center;
  font-size: 18px;
  line-height: 24px;
  color: #4a4b4d;
  font-family: FontChilenaFina;
  text-align: justify;

  @media screen and (max-width: 480px) {
    margin-bottom: 10px;
  }
`;

export const ImgWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 390px;

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
`;

export const Image = styled.img`
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  border-radius: 50%;
  filter: saturate(130%);
  transition: all 0.6s ease-in-out;

  &:hover {
    filter: saturate(120%);
    transform: scale(0.9);
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    max-height: 300px;
  }
`;
