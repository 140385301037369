import styled from "styled-components";

export const Wrap = styled.div`
  max-width: 68.37%;
  margin: 8rem 2rem 2rem 17.8rem;
  padding: 0 20px;

  @media screen and ( max-width: 480px ) {
  max-width: 100%;
  margin: 8rem 2rem 2rem 0rem;
  padding: 0 20px;
  }
`;

export const Over = styled.div`
  max-width: 100%;
  padding: 0 0px;

  

`;

export const Title = styled.h1`
  font-size: 2.5rem;
  line-height: 1.3;
  font-weight: 700;
  margin: 2rem 0;

  @media screen and ( max-width: 480px ) {
    width: 110%;
    margin-top: 1rem;
  }
`;

export const Subtitle = styled.h2`
  font-size: 1rem;
  line-height: 1.3;
  font-weight: 700;
  margin: 2rem 0;
`;

export const Description = styled.p`
  font-size: 0.105;
  line-height: 1.3;
  margin: 2rem 0;
  width: 97%;
  color: #000;
  white-space: pre-wrap;
  text-align: justify;

`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  filter: brightness(110%) contrast(110%) saturate(120%) blur(0.5px);
`;

export const Video = styled.video`
  width: 100%;
  height: 100%;
  filter: brightness(110%) contrast(110%) saturate(120%) blur(0.5px); 
`

export const Data = styled.p`
  color: #5c5c5c;
  font-size: 0.8rem;
  font-weight: 400;
  margin: 0.5rem 0rem 0rem 0rem;
  text-transform: uppercase;
`;

export const InfoImage = styled.p`
  color: #5c5c5c;
  font-size: 0.870rem;
  font-weight: 400;
  margin: 0.5rem 0rem 0rem 0rem;
`;

export const Autor = styled.p`
  margin-top: 0.5rem;
  font-size: 1.105rem;
  font-weight: bold;
`;

export const NewsChilenter = styled.p`
  margin-top: 0.3rem;
  font-weight: lighter;
  color: #5c5c5c;

& hr {
    border: none;
    border-top: 2px solid #000;
    margin-top: 1rem;
    margin-bottom: 20px;
    margin-left: 0rem;
    width: 7%;
  }
`;

export const WrapAll = styled.div`
  width: 74.05%;

  @media screen and ( max-width: 480px ) {
    width: 110%;
  }
`;

export const Localitation = styled.h1`
  margin-top: 0.3rem;
    font-weight: lighter;
    color: #5c5c5c;

  & hr {
      border: none;
      border-top: 2px solid #000;
      margin-top: 1rem;
      margin-bottom: 20px;
      margin-left: 0rem;
      width: 7%;
    }

  @media screen and ( max-width: 480px ) {
    width: 110%;
  }  
`;

export const Quote = styled.blockquote`
  margin: 1rem 0;
  padding: 1rem;
  background-color: #f9f9f9;
  border-left: 4px solid #ccc;
`;
