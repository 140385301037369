import styled from "styled-components";
import backgroundImg from '../../images/blue.jpg';

export const Contact = styled.p`
    margin-bottom: 0.8rem;
    font-size: 14px;

    @media screen and ( max-width: 820px ) {
        margin: 0.5rem 0rem 0.8rem -5rem;
        font-size: 14px;
    }
`;

export const FooterContainer = styled.footer`
    background-image: url(${backgroundImg});
`;

export const FooterWrap = styled.div`
    padding: 48px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1100px;
    margin: 0 auto;
`;

export const FooterLinksContainer = styled.div`
    display: flex;
    justify-content: center;
    text-decoration: none;
    
    @media screen and ( max-width: 820px ) {
        padding-top: 32px;
        margin-left: 80px;
    }
`;

export const FooterLinksWrapper = styled.div`
    display: flex;

    @media screen and ( max-width: 820px ) {
        flex-direction: column;
    }
`;

export const FooterLinkItems = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: auto;
    box-sizing: border-box;
    color: #fff;

    @media screen and ( max-width: 420px ){ 
        margin: 0px;
        width: 100%;      
    }
`;

export const FooterLinkTitle = styled.h1`
    font-size: 20px;
    margin-bottom: 16px;  

    @media screen and ( max-width: 820px) {
        margin-left: -80px;
        padding-left: 0px;
    }
`;

export const FooterLink = styled.div`
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
    font-size: 14px;
    text-align: justify;
    cursor: pointer;
           
    @media screen and ( max-width: 820px) {
        margin-left: -80px;
        padding-left: 0px;
    }
`;


export const SocialMedia = styled.section`
    max-width: 1000px;
    width: 100%;

    @media screen and ( max-width: 480px ) {
    max-width: 1000px;
    width: 100%;  
    margin: 0rem 0rem 0rem 0rem;
    }

    @media screen and ( max-width: 880px ) {
    max-width: 1000px;
    width: 100%;
    height: auto ;
    margin: 0rem 0rem 0rem 0rem;
    padding: 0rem 0rem 10rem 0rem;
    }
`;

export const SocialMediaWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1100px;
    height: auto;
    margin: 40px auto 0 auto;

    @media screen and ( max-width: 820px) {
        flex-direction: column;
        margin: 0rem 0rem 0rem 0rem;
    }
`;

export const SocialLogo = styled.div`
    color: #fff;
    justify-self: start;
    text-decoration: none;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    font-weight: bold;
    filter: saturate(200%);
    transition: all 0.3s ease-in-out;
    
    :hover{
        transform: scale(1.1);
        filter: saturate(200%);
    }
`;

export const WebsiteRights = styled.small`
    color: #fff;
    margin-bottom: 16px;

    @media screen and (max-width: 480px) {
        width: 70%;
    }
`;

export const SocialIcons = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
`;

export const SocialIconLink = styled.a`
    color: #fff;
    font-size: 24px;  
`;

export const Chome = styled.img`
    width: 300px;
    display: flex;
    align-items: center;
    pointer-events: none;
    filter: saturate(200%);

    @media screen and ( max-width: 768px ) {
        display: flex;
        align-items: center;
        height: 130px;
        width:auto;     
    }
`;

export const Mc = styled.a`

    @media screen and ( max-width: 768px){
        margin-left: -353px;
        margin-top: 10px;
        display: flex;
        justify-items:center;    
    }   
`;

export const Mc1 = styled.img`
    width: 242px;
    height: 120px;
    padding-bottom:20px ;
    margin-left: 32px;
    margin-top: 0px;
    margin-bottom: -18px;
    
    @media screen and ( max-width: 768px){
        margin-left: -300px;
        margin-top: 300px;
        display: flex;
        justify-items:center;    
    }  
`;

export const Mc2 = styled.a`
    width: 230px;
    height: auto;
    padding-bottom:20px ;
    margin-left: -20px;
    margin-top: -7px;
     
    @media screen and ( max-width: 768px){
        margin-left: -352px;
        margin-top: -20px;
        display: flex;
        justify-items:center;    
    }  
`;

export const Image = styled.img`
    width: 40px;
    height: 40px;
    filter: saturate(200%);
    transition: 0.1s ease-in-out;
    
    &:hover{
        width: 45px;
        height: 45px;       
    } 
`;

export const Img = styled.img`
    width: 150px;
    height: auto;
    padding-bottom:20px ;
    margin-left: 80px;
    filter: saturate(110%);
    transition: all 0.2s ease-in-out;
    border-radius:8px;
    
    :hover{
        transform: scale(1.2);
    }

    @media screen and ( max-width: 768px){
        margin-left: 100px;
        margin-top: 0px;
        display: flex;
        justify-items:center;    
    } 
`;
