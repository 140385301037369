import React, {useState} from 'react';
import Footer from '../Components/FooterNews';
import HeroSectionNews from '../Components/HeroSection News';
import Navbar from '../Components/NavbarNews';
import Sidebar from '../Components/SidebarNews';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import Avatar from '../images/favicon.png';
import PostNewsFirstLook from '../Components/PostNews/PostNewsFirstLook';
import FrontPageNews from '../Components/FrontPageNews/FrontPageNews';
import Newsmore from '../Components/NewsMore/Newsmore';



const Home = () => {

  
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () =>{
    setIsOpen(!isOpen)
  }

  
  return (
    <>
      <FloatingWhatsApp 
        phoneNumber='+56967275021'
        accountName='Chilenter Noticias'
        statusMessage='Normalmente responde en 7min'
        chatMessage='¡Hola!  Bienvenido a la sección de Noticias 📊 📈 📉 ¿Cómo  puedo  ayudarte?😉 '
        allowClickAway= {true}
        allowEsc={true}
        notification={false}
        notificationSound={false}
        otificationLoop='3'
        avatar={Avatar}
        placeholder='Escribe un mensaje'
        darkMode={false}
      />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroSectionNews/>
      <Newsmore/>
      <FrontPageNews/>
      <PostNewsFirstLook/>
      <Footer/>
    </>
  )
}

export default Home;
