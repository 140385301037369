import React, {useEffect, useState} from 'react';
import {FaBars} from 'react-icons/fa'
import { IconContext } from 'react-icons/lib';
import { animateScroll as scroll} from 'react-scroll';
import { Nav, 
  NavbarContainer, 
  NavLogo, 
  MobileIcon, 
  NavMenu, 
  NavItem, 
  NavLinks, 
  Clogo,
  StyledLink,
  DateTime,
  H1
} from './NavbarElements';
import Logo  from '../../images/ChilenterWhiteNavbar.png';


const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false)
  const [dateTime, setDateTime] = useState(new Date().toLocaleDateString('es-ES', { month: 'long' }))

  const changeNav = () => {
    if(window.scrollY >=80){
      setScrollNav(true)
    } else {
      setScrollNav(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', changeNav)

    const intervalId = setInterval(() => {
      setDateTime(new Date().toLocaleString('es-ES', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }));

    }, 1000);

    return () => clearInterval(intervalId);
  }, [] )

  const toggleHome = () =>{
    scroll.scrollToTop();
  }
  

  return (
    <>
        <IconContext.Provider value={{ color: '#fff'}}>
        <Nav scrollNav={scrollNav}
        >
            <NavbarContainer >
               <NavLogo 
                to='#'
                onClick={toggleHome}> 
                <Clogo  duration={2500} src={Logo}></Clogo>
                <H1>Noticias</H1>
                <DateTime>{dateTime}</DateTime> 
               </NavLogo>
                <MobileIcon onClick={toggle}>
                  <FaBars />
                </MobileIcon>   
            </NavbarContainer> 
            <NavMenu >
                  <NavItem>
                    <StyledLink 
                    to="/" 
                    onClick={toggleHome}
                    smooth={true} 
                    duration={2500} 
                    spy={true}
                    exact='true'
                    offset={-80}
                    >Inicio</StyledLink>
                  </NavItem>
                  <NavItem>
                  <NavLinks 
                    to="destacadas"
                    smooth={true} 
                    duration={3000} 
                    spy={true}
                    exact='true'
                    offset={-150}>Destacadas</NavLinks>
                  </NavItem>
                  <NavItem>
                  <NavLinks to="PostNew"
                    smooth={true} 
                    duration={3000} 
                    spy={true}
                    exact='true'
                    offset={-215}>Últimas Noticias</NavLinks>
                    </NavItem>
                  <NavItem>
                    <NavLinks 
                    to="footerTwo"
                    smooth={true} 
                    duration={5800} 
                    spy={true}
                    exact='true'
                    offset={-80}
                    >Contáctanos
                    </NavLinks>
                  </NavItem>
                </NavMenu>              
        </Nav>
      </IconContext.Provider>
    </>
  )
};

export default Navbar;