import React from 'react'
import useFetchData from '../Hooks/userFetchData'
import { Description, ImageText, Video, PostNewsContainer, PostNewsH1, PostNewsWrapper, Data, PostNewsContent, Link, DataOne} from './FrontPageNewsElements'
import TextTruncate from 'react-text-truncate';

function FrontPageNews() {
  const { loading, result, error } = useFetchData(
    'https://strapi-133505-0.cloudclusters.net/api/front-page-newses?populate=*',
  );

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error...</p>;

  const baseUrl = "https://strapi-133505-0.cloudclusters.net";

  return (
    <>
      {result.data.map((postOne, id) => {
        const mediaData = postOne.attributes.Media.data[0].attributes;
        const mediaUrl = baseUrl + mediaData.url;
        const fileExtension = mediaData.ext.toLowerCase();

        return (
          <PostNewsContainer key={id} id=''>
            <Link href={`destacado/front-page-news/${postOne.attributes.Title}/${postOne.attributes.Date}`} passHref style={{ textDecoration: 'none', color: '#000' }}>
              <PostNewsWrapper>
                
                {fileExtension === '.mp4' || fileExtension === '.webm' ? (
                  <Video src={mediaUrl} controls muted={false} autoPlay={true}/>
                ) : fileExtension === '.jpg' || fileExtension === '.jpeg' || fileExtension === '.png' ? (
                  <ImageText src={mediaUrl} alt='image' />
                ) : ( 
                  <p>Tipo de contenido no compatible</p>
                )}
                <PostNewsContent>
                  <PostNewsH1 id='NEWS'>{postOne.attributes.Title ? postOne.attributes.Title : ''}</PostNewsH1>
                  <Description>
                    <TextTruncate
                      style={{ color: '#5A5A5A', fontSize: '1rem' }}
                      key='item-first'
                      line={7}
                      element='p'
                      truncateText='...'
                      text={postOne.attributes.Description ? postOne.attributes.Description : ''}
                    />
                  </Description>
                  <Data>{postOne.attributes.Localitation}</Data>
                  <DataOne>{postOne.attributes.Date}</DataOne>
                </PostNewsContent>
              </PostNewsWrapper>
            </Link>
          </PostNewsContainer>
        );
      })}
    </>
  );
}


export default FrontPageNews;

  
