import React, {useState} from 'react';
import Footer from '../Components/Footer';
import HeroSection from '../Components/HeroSection';
import Navbar from '../Components/Navbar';
import Sidebar from '../Components/Sidebar';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import Avatar from '../images/favicon.png';
import Index from '../Components/InfoSectionBackend';
import IndexTwo from '../Components/InfoSectionBackendTwo';
import ErrorBoundary from '../Components/Erros/ErrorBoundary';
import DirectoryBackend from '../Components/DirectoryBackend/Directorio';
import DirectoryBackendTow from '../Components/DirectoryBackendTwo/Directorio';




const Home = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () =>{
    setIsOpen(!isOpen)
  }
  


  return (
    <>
      <FloatingWhatsApp 
        phoneNumber='+56967275021'
        accountName='Chilenter'
        statusMessage='Normalmente responde en 7min'
        chatMessage='¡Hola! ¿Cómo puedo ayudarte?😉 '
        allowClickAway= {true}
        allowEsc={true}
        notification={false}
        notificationSound={false}
        notificationLoop='3'
        avatar={Avatar}
        placeholder='Escribe un mensaje'
        darkMode={false}
      />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroSection/>
      <ErrorBoundary>
      <Index/>
      <IndexTwo/>
      </ErrorBoundary>
      <DirectoryBackend/>
      <DirectoryBackendTow/>
      <Footer/>
    </>
  )
}

export default Home;